import triggerDownload from "../../../helpers/triggerDownload";

const fetchFactoringCollatedFiles = (id, data, isDebtor) => async (
  dispatch,
  getState,
  { api }
) => {
  const response = !isDebtor
    ? await api.factoring.report(id, data)
    : await api.factoring.debtorReport({ factoring_id: id, ...data });

  if (response.length > 0 && response[0]?.download_url) {
    triggerDownload(response[0].download_url);
  } else {
    triggerDownload(response.download_url);
  }

  return response;
};

export default fetchFactoringCollatedFiles;
