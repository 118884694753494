import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withProps,
  withState,
  shallowEqual
} from "recompose";
import { get, isEmpty, isEqual } from "lodash";

import {
  createBankAccount,
  createCardAccount,
  createFactoringNote,
  createFundingRequest,
  createWireAccount,
  defaultBankAccount,
  deleteAccount,
  deleteAttachment,
  deleteClientProfile,
  deleteFactoringNote,
  fetchAttachments,
  fetchExport,
  fetchFactoringCollatedFiles,
  fetchFactoringProfile,
  fetchFactoringProfileHistory,
  fetchNotesTimeline,
  fetchPlaidUser,
  fetchReserve,
  fetchReserveAggregate,
  fetchStats,
  fetchUser,
  getActiveReserve,
  getAttachments,
  getCollatedAttachments,
  getFactorClient,
  getFactoringProfileHistory,
  getNotesTimeline,
  getPendingNegativeReserve,
  getPlaidUser,
  getReserve,
  getStats,
  getUser,
  getUserId,
  sendTransferDocuments,
  updateFactoringNote,
  updateFactoringProfile,
  updateUser,
  uploadAttachments,
  fetchNotesTimelineNext,
  getNotesTimelineMore,
  getNoaAttatchmentFile
} from "../../../../modules/factoringClient";
import {
  updateLineItem,
  getLineItemCategories,
  createTransactionNote
} from "../../../../modules/factoring";
import API from "../../../../api";
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from "../../../../modules/ui";
import PureProfile from "./Profile";
import { createFundingRequestForm, factorClientForm, noteForm } from "./forms";
import withTabs from "../../../../components/withTabs";

import tabs from "../tabs";
import { getUserType } from "apps/haulpay-frontend/src/modules/auth";

export const Profile = PureProfile;

const mapStateToProps = state => ({
  attachments: getAttachments(state),
  factoringClient: getFactorClient(state),
  plaidUser: getPlaidUser(state),
  activeReserve: getActiveReserve(state),
  userId: getUserId(state),
  pendingNegativeReserve: getPendingNegativeReserve(state),
  user: getUser(state),
  dialog: getDialog(state),
  notes: getNotesTimeline(state),
  reserve: getReserve(state),
  getFactoringProfileHistory: getFactoringProfileHistory(state),
  getStats: getStats(state),
  collatedAttachments: getCollatedAttachments(state),
  lineItemCategories: getLineItemCategories(state),
  moreNotes: getNotesTimelineMore(state),
  noaAttatchmentFile: getNoaAttatchmentFile(state),
  userType: getUserType(state)
});

const mapDispatchToProps = {
  fetchNotesTimelineNext,
  fetchFactoringProfile,
  fetchAttachments,
  fetchUser,
  fetchPlaidUser,
  fetchReserveAggregate,
  deleteClientProfile,
  openDialog,
  closeDialog,
  createCardAccount,
  createBankAccount,
  createWireAccount,
  openSnackbar,
  fetchNotesTimeline,
  fetchReserve,
  updateFactoringProfile,
  updateUser,
  uploadAttachments,
  deleteAttachment,
  createFactoringNote,
  updateFactoringNote,
  deleteFactoringNote,
  createFundingRequest,
  fetchFactoringProfileHistory,
  fetchStats,
  fetchFactoringCollatedFiles,
  sendTransferDocuments,
  defaultBankAccount,
  fetchExport,
  deleteAccount,
  updateLineItem,
  createTransactionNote
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("isLoading", "setLoading", true),
  withState("isLoadingAuditTrail", "setLoadingAuditTrail", true),
  withState("isLoadingGBCB", "setLoadingGBCB", true),
  withState("isLoadingInternalClientNotes", "setLoadingInternalClientNotes", false),
  withState("wasICNTabClicked", "setICNTabClicked", false),
  withState("paymentProfileOptions", "setPaymentProfileOptions", {}),
  withProps(
    ({
      history: { push },
      match: {
        params: { id }
      },
      getFactoringProfileHistory,
      factoringClient,
      openDialog,
      createCardAccount,
      createBankAccount,
      createWireAccount,
      userId,
      openSnackbar,
      updateFactoringProfile,
      user,
      updateUser,
      uploadAttachments,
      deleteAttachment,
      dialog,
      closeDialog,
      createFactoringNote,
      deleteFactoringNote,
      createFundingRequest,
      getStats,
      sendTransferDocuments,
      defaultBankAccount,
      deleteAccount,
      updateFactoringNote,
      createTransactionNote,
      userType,
      fetchFactoringProfileHistory,
      setLoadingAuditTrail,
      isLoadingAuditTrail,
      fetchReserve,
      setLoadingGBCB,
      isLoadingGBCB,
      fetchNotesTimeline,
      setLoadingInternalClientNotes,
      isLoadingInternalClientNotes,
      setICNTabClicked,
      wasICNTabClicked
    }) => {
      return {
        disableAll: userType === "factoring_remote_admin",
        clientStats: getStats(id),
        auditLog: getFactoringProfileHistory(id),
        tabs: tabs(id, factoringClient.allow_self_finance_fr),
        handleRoute: route => push(route),
        id,
        userId,
        updateFactoringProfile: updateFactoringProfile(id),
        factorClientForm: factorClientForm(
          updateFactoringProfile(id),
          updateUser(userId),
          openSnackbar,
          factoringClient,
          user,
          uploadAttachments,
          id,
          userType
        ),
        handleEditPayments: () => {
          openDialog("edit-payment");
        },
        handlePaymentProfilesLink: () => {
          openDialog("payment-profile-link-dialog");
        },
        handleEditReceivableNotes: (...args) => {
          openDialog("factor-receivables-notes", ...args);
        },
        handleDeleteProfile: () => {
          openDialog(
            "confirm",
            "Confirm Delete",
            "Are you sure you want to delete this Factor Client?"
          );
        },
        handleSendTransferDocuments: async () => {
          try {
            await sendTransferDocuments(id);
            openSnackbar("success", "Collated Attachments has been sent!");
          } catch (e) {
            openSnackbar("error", "Error while sending collated Attachments!");
          }
        },
        handleDeleteProfileConfirm: () => {
          closeDialog();
        },
        handleAttachmentDelete: id =>
          openDialog(
            "delete-factor-client-attachment",
            "Confirm Delete Attachment",
            "Are you sure you want to delete this attachment?",
            {
              id
            }
          ),
        handleDeleteAttachmentConfirm: async () => {
          const attachmentId = get(dialog, "data.id");
          await deleteAttachment(id, attachmentId);
          closeDialog();
        },
        noteForm: noteForm(
          id,
          createFactoringNote,
          updateFactoringNote,
          openSnackbar
        ),
        createFundingRequestForm: createFundingRequestForm(
          createFundingRequest,
          id,
          openSnackbar,
          closeDialog,
          createTransactionNote
        ),
        handleDeleteNote: async noteId => {
          try {
            await deleteFactoringNote(id, noteId);
            openSnackbar("success", "Deleted Note!");
          } catch (err) {
            openSnackbar("error", err);
          }
        },
        handleCreateFundingRequest: () => {
          openDialog("factor-client-create-funding-request");
        },
        openEditLineItem: (...args) =>
          openDialog("factor-open-line-item", ...args),
        closeEditLineItem: () => closeDialog(),
        handleAuditTrailsClick: async () => {
          setLoadingAuditTrail(true);
          await fetchFactoringProfileHistory(id);
          setLoadingAuditTrail(false);
        },
        handleGBCBClick: () => {
          setLoadingGBCB(true);
          fetchReserve(userId)
            .catch((err) => {
              console.error(`Error fetching reserves: ${err}`);
            })
            .finally(() => {
              setLoadingGBCB(false);
            });
        },
        handleInternalClientNotesClick: async () => {
          if (!wasICNTabClicked) {
            setICNTabClicked(true);
            setLoadingInternalClientNotes(true);
            await fetchNotesTimeline(id);
            setLoadingInternalClientNotes(false);
          }
        }
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const { id, setLoading } = this.props;
      setLoading(true);
      const results = await Promise.allSettled([
        this.props.fetchFactoringProfile(id),
        this.props.fetchAttachments(id),
        this.props.fetchStats(id),
        this.props.fetchFactoringCollatedFiles(id),
      ]);

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Error in promise ${index + 1}:`, result.reason);
        }
      });
      setLoading(false);
    },
    async componentDidUpdate(prevProps) {
      const currentUser = this.props.userId;
      const prevUser = prevProps.userId;
      if (
        !isEqual(prevProps.factoringClient, this.props.factoringClient) &&
        !isEmpty(this.props.factoringClient)
      ) {
        try {
          const mc = get(this.props.factoringClient, "company_profile.mc", "");
          const dot = get(
            this.props.factoringClient,
            "company_profile.dot",
            ""
          );
          const res = await API.factoring.paymentProfile.checkExistingPaymentProfile(
            mc,
            dot
          );
          this.props.setPaymentProfileOptions(res);
        } catch (e) {}
      }
      if (currentUser !== prevUser && isEmpty(this.props.user)) {
        this.props.setLoading(true);
        const results = await Promise.allSettled([
          this.props.fetchUser(currentUser),
          this.props.fetchPlaidUser(currentUser),
          this.props.fetchReserveAggregate(currentUser),
        ]);
        results.forEach((result, index) => {
          if (result.status === 'rejected') {
            console.error(`Error in promise ${index + 1}:`, result.reason);
          }
        });
        this.props.setLoading(false);
      }
    }
  }),
  withTabs
)(PureProfile);
