import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

const WaitView = ({ width =  '100%', height = '100vh' }): JSX.Element => (
  <Grid
    container
    xs={12}
    style={{ width: width, height: height }}
    justify="center"
    alignItems="center"
  >
    <CircularProgress />
  </Grid>
);

export default WaitView;
