import React, { useEffect, useState } from 'react';
import { PlaidLink } from 'react-plaid-link';
import PLAID from '../api/plaid';
import { CircularProgress } from '@material-ui/core';
import Colors from '../theme/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    buttonProgress: {
        color: Colors.secondary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default withStyles(styles)(function PlaidLinkComponent({
    submitToken,
    handleSubmit,
    children,
}) {
    const [linkToken, setLinkToken] = useState(null);
    const [isFetching, setIsFetching] = useState(null);

    useEffect(() => {
        const fetchLinkToken = async () => {
            try {
                setIsFetching(true);
                const { link_token } = await PLAID.getLinkToken();
                setLinkToken(link_token);
            } catch (err) {
                console.error('Error fetching link token:', err);
            } finally {
                setIsFetching(false);
            }
        };
        !linkToken && fetchLinkToken();
    }, [setLinkToken, linkToken]);

    return (
        <>
            {linkToken && (
                <PlaidLink
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        color: 'white',
                        fontSize: 14,
                        width: '100%',
                    }}
                    env={process.env.REACT_APP_PLAID_ENV}
                    token={linkToken}
                    onSuccess={(token, { accounts }) => {
                        submitToken(
                            token,
                            accounts.map((account) => account.id),
                        );
                        handleSubmit && handleSubmit();
                    }}
                >
                    {children}
                </PlaidLink>
            )}
            {isFetching && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </>
    );
});
