import { withStyles } from '@material-ui/styles';
import { merge } from 'lodash';

import {
    createStyles,
    ExpansionPanelDetails,
    Grid,
    WithStyles,
    Switch,
    FormControlLabel,
    Typography,
    MenuItem,
} from '@material-ui/core';
import React, { ChangeEventHandler, ReactNode, useState } from 'react';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpansionPanelSummary from '../../../../components/ExpansionPanelSummary';
import CustomTextField from '../../../../components/inputs/CustomTextField';
import commaQuery from '../../../../helpers/commaQuery';
import DatePicker from '../../../../components/inputs/DatePicker';
import CustomExpansionPanel from '../../../../components/ExpansionPanel';
import CSVButton from '../../../../components/buttons/CSVButton';
import PaymentTypeInput from './PaymentTypeInput';
import { Select } from '../../../../components/inputs/BoldInput';
import { fundingRequestType } from '../../../../helpers/fundingRequestType';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = createStyles({
    root: {
        width: '100%',
    },
});

interface NewEvent {
    target: {
        name: string;
        value: string;
    };
}

interface Props extends WithStyles<typeof styles> {
    children?: ReactNode;
    handleCSV: () => void;
    datetime_end: string;
    datetime_start: string;
    handleSearchFilter: (event: NewEvent) => void;
    onDateStartChange: ChangeEventHandler<HTMLInputElement>;
    onDateEndChange: ChangeEventHandler<HTMLInputElement>;
    onPaymentMethodChange: (method: string) => void;
    paymentMethod: string;
    expanded?: boolean;
}

const Filters = withStyles(styles)(({
    children,
    classes,
    handleCSV,
    handleSearchFilter,
    datetime_end,
    datetime_start,
    onDateStartChange,
    onDateEndChange,
    paymentMethod,
    onPaymentMethodChange,
    expanded,
}: Props): JSX.Element => {
    const [filterBrokerCarrier, setFilterBrokerCarrier] = useState([]);
    return (
        <div className={classes.root}>
            <CustomExpansionPanel expanded={expanded}>
                <ExpansionPanelSummary
                    expandIcon={<FontAwesomeIcon size="xs" icon={faSlidersH} />}
                />
                <ExpansionPanelDetails>
                    <Grid container spacing={32} direction="column">
                        <Grid item>
                            <Grid
                                container
                                spacing={32}
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item lg={2}>
                                    <CustomTextField
                                        fullWidth
                                        label="Starting Location"
                                        name="first_origin_location"
                                        onChange={handleSearchFilter}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <CustomTextField
                                        fullWidth
                                        label="Multiple Load #"
                                        name="user_load_number"
                                        onChange={handleSearchFilter}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <DatePicker
                                        fullWidth
                                        name="datetime_start"
                                        label="Date From"
                                        value={datetime_start}
                                        onChange={(event: any): void => {
                                            onDateStartChange(event.toString());
                                            const newEvent = {
                                                target: {
                                                    name: 'datetime_start',
                                                    value: event.toString(),
                                                },
                                            };

                                            handleSearchFilter(
                                                merge({}, newEvent),
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <PaymentTypeInput
                                        onChange={(event: any): void => {
                                            onPaymentMethodChange(
                                                event.target.value,
                                            );
                                            const newEvent = {
                                                target: {
                                                    name: event.target.name,
                                                    value: event.target.value,
                                                },
                                            };

                                            handleSearchFilter(
                                                merge({}, event, newEvent),
                                            );
                                        }}
                                        value={paymentMethod}
                                    />
                                </Grid>
                                <Grid item lg={1}>
                                    <CSVButton handleCSV={handleCSV} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                spacing={32}
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item lg={2}>
                                    <CustomTextField
                                        fullWidth
                                        label="Final Destination"
                                        name="final_destination_location"
                                        onChange={handleSearchFilter}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <CustomTextField
                                        fullWidth
                                        name="invoice_number"
                                        label="Multiple Invoice #"
                                        onChange={(event): void => {
                                            const newEvent = {
                                                target: {
                                                    name: event.target.name,
                                                    value: commaQuery(
                                                        event.target.value,
                                                    ),
                                                },
                                            };
                                            handleSearchFilter(newEvent);
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <DatePicker
                                        fullWidth
                                        name="datetime_end"
                                        label="Date To"
                                        value={datetime_end}
                                        onChange={(event: any): void => {
                                            onDateEndChange(event.toString());
                                            const newEvent = {
                                                target: {
                                                    name: 'datetime_end',
                                                    value: event.toString(),
                                                },
                                            };
                                            handleSearchFilter(newEvent);
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="has_new_upload"
                                                onChange={(
                                                    event,
                                                    checked,
                                                ): void => {
                                                    const newEvent = {
                                                        target: {
                                                            name: event.target
                                                                .name,
                                                            value: checked,
                                                        },
                                                    };
                                                    handleSearchFilter(
                                                        merge(
                                                            {},
                                                            event,
                                                            newEvent,
                                                        ),
                                                    );
                                                }}
                                                defaultChecked={false}
                                            />
                                        }
                                        label="New uploaded docs"
                                    />
                                </Grid>
                                <Grid item lg={2} />
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    spacing={32}
                                    alignItems="center"
                                    justify="space-between"
                                >
                                    <Grid item lg={2}>
                                        <CustomTextField
                                            fullWidth
                                            select
                                            inputProps={{
                                                multiple: true,
                                            }}
                                            name="contract_type"
                                            label="Contract Type"
                                            value={filterBrokerCarrier}
                                            onChange={(event: any) => {
                                                console.log(
                                                    'ON CHANGE',
                                                    event?.target,
                                                );
                                                setFilterBrokerCarrier(
                                                    event?.target?.value,
                                                );
                                                handleSearchFilter(event);
                                            }}
                                        >
                                            <ListSubheader>
                                                Standard Funding Requests
                                            </ListSubheader>
                                            <MenuItem value="STD_BROKER">
                                               Broker Factored
                                            </MenuItem>
                                             <MenuItem value="STD_CARRIER">
                                               Carrier Factored
                                            </MenuItem>
                                            <ListSubheader>
                                                Non Factored Funding Requests
                                            </ListSubheader>
                                            <MenuItem value="NON_FACTORED_STANDARD">
                                                Non-Factored Pay Carrier
                                            </MenuItem>
                                            <MenuItem value="NON_FACTORED_BILL_OUT">
                                                Non-Factored Bill Out Only
                                            </MenuItem>
                                            <ListSubheader>
                                                Self Finance Funding Request
                                            </ListSubheader>
                                            <MenuItem value="SELF_FINANCE_NO_INVOICE">
                                                Self Finance No invoice
                                            </MenuItem>
                                            <MenuItem value="SELF_FINANCE_WITH_INVOICE">
                                                Self Finance with invoice
                                            </MenuItem>
                                        </CustomTextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </CustomExpansionPanel>
        </div>
    );
});

export default Filters;
