import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  assign,
  findIndex,
  findLast,
  get,
  head,
  map,
  merge,
  toPairs,
  isNull,
  sortBy,
  omit,
  filter,
  isEmpty,
  find
} from 'lodash';
import {
  compose,
  lifecycle,
  withProps,
  withHandlers,
  withState
} from 'recompose';
import { DateTime } from 'luxon';
import { Grid, IconButton, Typography } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  ReplyAll as ReplyAllIcon
} from "@material-ui/icons";
import { getCurrentUserId, getUserType } from "../../../modules/auth";
import {
  closeEditLineItem,
  closeEditNote,
  closeSendNOA,
  createClientNote,
  createLineItem,
  createTransactionNote,
  deleteAttachment,
  deleteClientNote,
  deleteFundingRequest,
  deleteLineItem,
  deleteTransactionNote,
  fetchAttachments,
  fetchClientNotes,
  fetchCollated,
  fetchDebtorProfile,
  fetchDebtorRelation,
  fetchFactoringProfile,
  fetchFundingRequest,
  fetchInvoice,
  fetchCarrierInvoice,
  fetchLineItemCategories,
  fetchLineItems,
  fetchPurchases,
  fetchReceivables,
  fetchTransactionNotes,
  getAttachments,
  getClientNotes,
  getCollatedDoc,
  getDebtorForFundingRequest,
  getDebtorProfile,
  getDebtorRelation,
  getEditLineItem,
  getEditNote,
  getFactoringIdForFundingRequest,
  getFactoringProfile,
  getFundingRequest,
  getInvoiceDoc,
  getIsDeletingFundingRequest,
  getLineItemCategories,
  getLineItems,
  getPurchases,
  getReceivables,
  getSendNOA,
  getTransactionNotes,
  getUserForFundingRequest,
  openEditLineItem,
  openEditNote,
  openSendNOA,
  sendNOA,
  updateClientNote,
  updateFundingRequest,
  updateLineItem,
  updateNOADates,
  updateTransactionNote,
  getFundingRequestStatusAt,
  fetchCommodities,
  fetchFreightClass,
  getCollatedPdf,
  getCarrierInvoiceDoc,
  ReversalLineItems
} from "../../../modules/factoring";
import {
  fetchChargebacksAndGivebacks,
  fetchReserve,
  fetchReserveAggregate,
  getActiveReserve,
  getChargeBacksAndGiveBacks,
  getPendingNegativeReserve,
  getPendingPositiveReserve,
  linkRelatedLineItem
} from '../../../modules/reserve';
import {
  closeDialog,
  getDialog,
  openDialog,
  openSnackbar
} from '../../../modules/ui';

import { updateFactoringDebtor } from '../../../modules/debtor';

import formatPennies from '../../../helpers/format/formatPennies';
import formatPercent from '../../../helpers/format/formatPercent';
import formatStatus from '../../../helpers/format/formatStatus';
import formatDate from '../../../helpers/format/formatDate';
import formatDays from '../../../helpers/format/formatDays';
import statusColor from '../../../helpers/statusColor';
import splitAndCase from '../../../helpers/format/splitAndCase';
import getPhone from '../../../helpers/getPhone';
import getExtension from '../../../helpers/getExtension';
import formatPhone from '../../../helpers/format/formatPhone';
import formatAddress from '../../../helpers/format/formatAddress';

import withTabs from '../../../components/withTabs';
import Link from '../../../components/Link';
import PenniesInput from '../../../components/inputs/PenniesInput';
import Can from '../../../components/Can';
import { fundingRequest as fundingRequestSchema } from './validation';
import PureFundingRequest from './FundingRequest';
import { clearFiles } from '../../../modules/upload';
import API from '../../../api';

export { default as FundingRequest } from './FundingRequest';

const getDebtorInfo = ({
  bill_to_company_email,
  bill_to_company_phone,
  bill_to_address,
  ...props
}) => {
  const { email, phone_number, address, ...debtor } =
    get(props, 'debtor', {}) || {};
  const extension = getExtension(bill_to_company_phone);
  const credit = get(debtor, 'credit_approved', '');
  return {
    email: bill_to_company_email || email,
    phoneNumber: `${formatPhone(getPhone(bill_to_company_phone))}${extension ? `;${extension}` : ''
      }`,
    address: bill_to_address || address,
    ...debtor,
    credit: formatStatus(credit)
  };
};

const getGeneralInfo = (
  {
    discount_rate: rate,
    funded_at: fundedAt,
    created: requestedAt,
    factoring = {},
    ...fundingRequest
  },
  { status: receivableStatus } = {}
) => {
  return {
    rate,
    requestedAt: formatDate(requestedAt),
    fundedAt: formatDate(fundedAt),
    transactionRep: get(fundingRequest, 'assigned_admin.id'),
    receivableStatus: formatStatus(receivableStatus)
  };
};

const getLineItemsTable = (lineItems, openDialog, openEditLineItem, status) => {
  const isNotAdjustable = ["approved", "paid", "declined", "non-factored"].includes(status);
  return map(
    lineItems,
    ({
      id,
      funding_request: fundingRequest,
      amount_requested: amount,
      amount_approved: amountFunded,
      discount_rate: discountRate,
      category: categoryId,
      category_applied_against: appliedAgainst,
      bill_with_funding_request: billWithFundingRequest,
      description,
      payment_status,
      approval_status,
      category_name: category,
      created: requestedAt,
      approval_status: approve,
      is_adjustment: isReversal,
      adjusted_line_item: adjustedLineItem,
      changed_by: changedBy,
      _amount_requested: amountRequested,
      ...rest
    }) => {
      const relatedInvoice = get(
        rest,
        'related_line_item_data.funding_request_invoice_number'
      );
      const relatedFundingRequest = get(
        rest,
        'related_line_item_data.funding_request'
      );
      return {
        id,
        amount,
        fundingRequest,
        discountRate,
        amountFunded,
        payment_status,
        approval_status,
        isReversal,
        amountRequested,
        category: (
          <Grid container="row" spacing={8}>
            <Grid item>{category}</Grid>
            {relatedInvoice && (
              <Grid item>
                <Link
                  to={`/admin/fundingrequest/${relatedFundingRequest}/`}
                  target="_blank"
                  onClick={event => {
                    event.preventDefault();
                    window.open(
                      `/admin/fundingrequest/${relatedFundingRequest}/`
                    );
                  }}
                >
                  {relatedInvoice}
                </Link>
              </Grid>
            )}
          </Grid>
        ),
        categoryId,
        description,
        billWithFundingRequest,
        appliedAgainst,
        approve,
        requestedAt,
        adjustedLineItem,
        changedBy,
        actions: (
          <React.Fragment>
            {!isReversal && !isNotAdjustable ?
              <>
                <IconButton
                  aria-label="Edit"
                  onClick={() =>
                    openDialog("view-lineitem-history", "", "", {
                      lineItem: id,
                      fundingRequest
                    })
                  }
                >
                  <VisibilityIcon color="secondary" />
                </IconButton>
                <Can
                  perform="lineitems:edit"
                  yes={() => (
                    <IconButton
                      aria-label="Edit"
                      onClick={() => openEditLineItem(id, { type: "edit" })}
                    >
                      <EditIcon color="secondary" />
                    </IconButton>
                  )}
                />
                <Can
                  perform="lineitems:delete"
                  yes={() => (
                    <IconButton
                      aria-label="Delete"
                      onClick={() =>
                        openDialog(
                          "confirm",
                          "Confirm Delete",
                          "Are you sure you want to delete this Line Item?",
                          {
                            lineItem: id,
                            fundingRequest
                          }
                        )
                      }
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                />
              </>
              :
              <>
                <Can
                  perform="lineitems:view"
                  yes={() => (
                    <IconButton
                      aria-label="reversal"
                      onClick={() => isReversal ? openDialog(

                        "line-item-reversal",
                        "",
                        "",
                        {
                          itemId: id,
                          amount,
                          adjustmentItem: {
                            adjustedBy: changedBy?.name,
                            description,
                            amount
                          }
                        }
                      ) : openEditLineItem(id, { type: "view" })
                      }
                    >
                      <VisibilityIcon color="secondary" />
                    </IconButton>
                  )}
                />
                <Can
                  perform="lineitems:edit"
                  yes={() => (
                    <IconButton
                      aria-label="Edit"
                      onClick={() => openEditLineItem(id, { type: "edit" })}
                    >
                      <EditIcon color="secondary" />
                    </IconButton>
                  )}
                />
              </>
            }
            {(!isReversal && isNotAdjustable) &&
              <>
                <Can
                  perform="lineitems:reversal"
                  yes={() => (
                    <IconButton
                      aria-label="reversal"
                      onClick={() =>
                        openDialog(
                          "line-item-reversal",
                          "",
                          "",
                          {
                            itemId: id,
                            amount
                          }
                        )
                      }
                    >
                      <ReplyAllIcon color="primary" />
                    </IconButton>
                  )}
                />
              </>
            }
          </React.Fragment>
        )
      };
    }
  )
};

const getFinancesInfo = purchases => {
  const items = map(
    purchases,
    ({
      funding_request: fundingRequest,
      id,
      category,
      amount,
      created: purchasedAt,
      posting_date: postingDate,
      purchase_trace_id: purchaseTraceId,
      purchase_check_number: purchaseCheckNumber,
      status,
      account_payment_method: {
        method: accountPaymentMethod = '',
        account_number_redacted: accountNumberRedacted = ''
      } = {}
    }) => ({
      fundingRequest,
      id,
      category: splitAndCase(category),
      amount: formatPennies(amount),
      purchasedAt: formatDate(postingDate),
      approvalDate: formatDate(purchasedAt),
      purchaseTraceId: purchaseTraceId || '',
      purchaseCheckNumber: purchaseCheckNumber || '',
      status: formatStatus(status),
      account: (
        <>
          <Typography variant="body1">
            {accountNumberRedacted
              ? `${accountPaymentMethod} (${accountNumberRedacted})`
              : accountPaymentMethod}
          </Typography>
        </>
      )
    })
  );
  return {
    purchases: items
  };
};

const getNOASummery = ({
  noa_active: active,
  noa_placed: placed,
  noa_sent: sent,
  noa_requested: requested,
  created : relationshipCreated
}) => ({
  active: active ? DateTime.fromISO(active).toISODate() : '',
  placed: placed ? DateTime.fromISO(placed).toISODate() : '',
  sent,
  requested,
  relationshipCreated
});

export const noaActiveForm = ({
  noaSummary: { active, placed },
  debtorRelation: relation,
  updateNOADates: update,
  openSnackbar
}) => ({
  enableReinitialize: true,
  initialValues: {
    active: active || ''
  },
  onSubmit: async (values, { setSubmitting }) => {
    let activeDate = null;

    if (values.active !== '' && !isNull(values.active)) {
      activeDate = DateTime.fromISO(values.active).toISO();
    }
    try {
      await update(
        get(relation, ['debtor', 'id'], relation.debtor),
        get(relation, ['factoring', 'id'], relation.factoring),
        activeDate,
        null
      );
      openSnackbar('success', 'Updated NOA Dates!');
    } catch (err) {
      openSnackbar('error', head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const noaForm = ({
  noaSummary: { active, placed },
  debtorRelation: relation,
  updateNOADates: update,
  openSnackbar
}) => ({
  enableReinitialize: true,
  initialValues: {
    active,
    placed
  },
  onSubmit: async (values, { setSubmitting }) => {
    try {
      await update(
        get(relation, 'debtor.id', relation.debtor),
        get(relation, 'factoring.id', relation.factoring),
        values.active !== '' ? DateTime.fromISO(values.active).toISO() : null,
        values.placed !== '' ? DateTime.fromISO(values.placed).toISO() : null
      );
      openSnackbar('success', 'Updated NOA Dates!');
    } catch (err) {
      openSnackbar('error', head(err));
    } finally {
      setSubmitting(false);
    }
  }
});

const fundingRequestForm = ({
  updateFundingRequest,
  fundingRequest: {
    purchase_order_number: poNumber,
    apply_reserve_holdback: applyReserveHoldback,
    self_finance_requested: selfFinanceRequested,
    payment_profile_id: PaymentProfileId,
    payment_profile_payment_method_display: PaymentProfilePaymentMethod,
    fuel_advance_payment_method_display: fuelAdvancePaymentMethod,
    payment_profile_accounts,
    carrier_pro_number: carrierProNumber,
    reserve_holdback: reserveHoldback,
    payout_days,
    status,
    accounts: factoringAccounts,
    status_note: statusNote,
    invoice_number: invoiceNumber,
    user_load_number: loadNumber,
    user_reference_number: clientInvoiceNumber,
    load_trailer_type: equipmentType,
    first_origin_location: firstPickUpAddress,
    final_destination_location: finalDeliveryAddress,
    load_length: loadLength,
    debtor = {},
    factoring = {},
    amount: invoiceAmount,
    amount_funded: amountFunded,
    unpaid_purchase_amount: unPaidPurchaseAmount,
    client_invoice_amount: clientInvoiceAmount,
    net_worth: debtorInvoiceAmount,
    payment_method: paymentMethod,
    user_notes: notesFromClient,
    invoice_notes: invoiceNotes,
    factoring_amount: brokerGrossPayAmount,
    factoring_unpaid_purchase_amount: brokerPayAmount,
    payment_profile_amount: carrierPayAmount,
    payment_profile_purchase_amount: carrierPurchaseAmount,
    payment_profile_funded_amount: carrierFundedAmount,
    factoring_funded_amount: brokerFundedAmount,
    factoring_discount_amount: brokerDiscountAmount,
    payment_profile_discount_amount: carrierDiscountAmount,
    factoring_fee_split_rate: factoringFeeSplitRate,
    fuel_advance_amount: fuelAdvanceAmount,
    fuel_advance_rate: fuelAdvanceRate,
    fuel_advance_fee: fuelAdvanceFee,
    commodity,
    freight_class: freightClass,
    sales_rep_name: salesRep,
    assigned_admin = {},
    invoice_date: invoiceDate,
    factored,
    payment_profile: paymentProfile,
    discount_rate: discountRate,
    payment_profile_discount_rate: paymentProfileDiscountRate,
    factoring_company: factoringCompany,
    non_factored_type: nonFactoredType,
    contract_type: contractType,
  } = {},
  fundingRequest,
  openSnackbar
}) => {
  const paymentProfileAccounts = (!isEmpty(paymentProfile) && !paymentProfile.enable_pay_via_check) ? filter(payment_profile_accounts, account => account.method !== 'CHECK') : payment_profile_accounts;
  const accounts = (!isEmpty(factoring) && !factoring.enable_pay_via_check) ? filter(factoringAccounts, account => account.method !== 'CHECK') : factoringAccounts;
  return {
    enableReinitialize: true,
    validationSchema: fundingRequestSchema,
    initialStatus: {},
    initialValues: {
      poNumber: poNumber || '',
      applyReserveHoldback: applyReserveHoldback || false,
      selfFinanceRequested: selfFinanceRequested || false,
      PaymentProfileId: PaymentProfileId || '',
      nonFactoredType: nonFactoredType || '',
      paymentProfilePaymentMethodDisplay: PaymentProfilePaymentMethod || {},
      fuelAdvancePaymentMethodDisplay: (fuelAdvancePaymentMethod || {}).method,
      paymentProfileAccounts: (!isEmpty(paymentProfileAccounts) && PaymentProfilePaymentMethod && isEmpty(find(paymentProfileAccounts, ['id', PaymentProfilePaymentMethod.id]))) ? [...paymentProfileAccounts, PaymentProfilePaymentMethod] : paymentProfileAccounts || [],
      PaymentProfilePaymentMethod: PaymentProfilePaymentMethod || {},
      carrierPaymentMethod: (PaymentProfilePaymentMethod || {}).id,
      fuelAdvancePaymentMethod: fuelAdvancePaymentMethod || {},
      factored: factored || false,
      paymentProfile: paymentProfile || {},
      carrierProNumber: carrierProNumber || '',
      invoiceDate: invoiceDate || '',
      payoutDays: payout_days,
      missingBOL: false,
      missingRateCon: false,
      missingReceipt: false,
      blurryBOL: false,
      blurryRateCon: false,
      blurryReceipt: false,
      attachments: [],
      notesFromClient: notesFromClient || '',
      accounts,
      salesRep,
      reserveHoldback: (reserveHoldback || 0) / 100,
      carrierPurchaseAmount: (carrierPurchaseAmount || 0) / 100,
      carrierFundedAmount: (carrierFundedAmount || 0) / 100,
      brokerFundedAmount: (brokerFundedAmount || 0) / 100,
      status: status || '',
      statusNote: statusNote || '',
      invoiceNumber: invoiceNumber || '',
      loadNumber: loadNumber || '',
      commodity: commodity || '0',
      freightClass: freightClass || '0',
      factoringFeeSplitRate: factoringFeeSplitRate || '0',
      clientInvoiceNumber: clientInvoiceNumber || '',
      firstPickUpAddress: {
        label: formatAddress(firstPickUpAddress),
        ...firstPickUpAddress
      },
      finalDeliveryAddress: {
        label: formatAddress(finalDeliveryAddress),
        ...finalDeliveryAddress
      },
      equipmentType: equipmentType || '',
      loadLength: loadLength || '',
      debtor,
      invoiceAmount: (invoiceAmount || 0) / 100,
      amountFunded: (amountFunded || 0) / 100,
      clientInvoiceAmount: (clientInvoiceAmount || 0) / 100,
      unPaidPurchaseAmount: (unPaidPurchaseAmount || 0) / 100,
      debtorInvoiceAmount: (debtorInvoiceAmount || 0) / 100,
      paymentMethod: paymentMethod || '',
      invoiceNotes: invoiceNotes || '',
      carrierPayAmount: (carrierPayAmount || 0) / 100,
      brokerPayAmount: (brokerPayAmount || 0) / 100,
      brokerGrossPayAmount: (brokerGrossPayAmount || 0) / 100,
      brokerDiscountAmount: (brokerDiscountAmount || 0) / 100,
      carrierDiscountAmount: (carrierDiscountAmount || 0) / 100,
      fuelAdvanceAmount: (fuelAdvanceAmount || 0) / 100,
      fuelAdvanceRate: fuelAdvanceRate || '',
      fuelAdvanceFee: (fuelAdvanceFee || 0) / 100,
      assignedAdmin: get(assigned_admin, 'id', ''),
      requireSignedBol: factoring.require_signed_bol || false,
      discountRate: discountRate || 0,
      paymentProfileDiscountRate: paymentProfileDiscountRate || 0,
      factoringCompany: factoringCompany || {},
      factoringDiscountRate: formatPercent(factoring.discount_rate) || 0,
      contractType: contractType || 'STD_BROKER',
    },
    onSubmit: async (
      {
        applyReserveHoldback,
        selfFinanceRequested,
        paymentProfileId,
        payoutDays,
        debtor,
        invoiceNumber,
        loadNumber,
        loadLength,
        statusNote,
        status,
        invoiceAmount,
        amountFunded,
        clientInvoiceAmount,
        unPaidPurchaseAmount,
        debtorInvoiceAmount,
        paymentMethod,
        attachments,
        missingBOL,
        missingRateCon,
        missingReceipt,
        blurryBOL,
        blurryRateCon,
        blurryReceipt,
        equipmentType,
        clientInvoiceNumber,
        notesFromClient,
        invoiceNotes,
        firstPickUpAddress,
        finalDeliveryAddress,
        fuelAdvanceAmount,
        fuelAdvanceRate,
        fuelAdvanceFee,
        commodity,
        freightClass,
        salesRep,
        brokerGrossPayAmount,
        brokerPayAmount,
        reserveHoldback,
        carrierProNumber,
        factoringFeeSplitRate,
        PaymentProfilePaymentMethod,
        fuelAdvancePaymentMethod,
        nonFactoredType,
        transactionRep,
        poNumber,
        noa,
        noa_active,
        noa_requested,
        noa_sent,
        self_finance_requested,
        contractType,
        carrierPaymentMethod,
        factored,
        carrierPayAmount,
      },
      { setSubmitting, resetForm }
    ) => {
      if (status === 'document_issue') {
        if (missingBOL) {
          statusNote += '- Missing BOL\n';
        }
        if (missingRateCon) {
          statusNote += '- Missing Rate Confirmation\n';
        }
        if (missingReceipt) {
          statusNote += '- Missing Receipt\n';
        }
        if (blurryBOL) {
          statusNote += '- Blurry BOL\n';
        }
        if (blurryRateCon) {
          statusNote += '- Blurry Rate Confirmation\n';
        }
        if (blurryReceipt) {
          statusNote += '- Blurry Receipt\n';
        }
      }
      const merged = assign(
        {},
        fundingRequest,
        {
          purchase_order_number: poNumber,
          apply_reserve_holdback: applyReserveHoldback,
          self_finance_requested,
          payment_profile_id: paymentProfileId,
          assigned_admin: transactionRep,
          non_factored_type: nonFactoredType,
          payment_profile_payment_method: (
            get(find(paymentProfileAccounts, ['id', carrierPaymentMethod]), 'method') || ''
          ).toLowerCase(),
          fuel_advance_payment_method: (
            get(fuelAdvancePaymentMethod, 'method') || ''
          ).toLowerCase(),
          fuel_advance_payment_method_display: fuelAdvancePaymentMethod,
          payment_profile_payment_method_display: PaymentProfilePaymentMethod,
          status,
          factoring_fee_split_rate: Number(factoringFeeSplitRate),
          carrier_pro_number: carrierProNumber,
          reserve_holdback: reserveHoldback,
          commodity: commodity === '0' ? null : Number(commodity),
          freight_class: freightClass === '0' ? null : Number(freightClass),
          payout_days: payoutDays,
          first_origin_location: firstPickUpAddress,
          final_destination_location: finalDeliveryAddress,
          status_note: statusNote,
          invoice_number: invoiceNumber,
          user_load_number: loadNumber,
          invoice_notes: invoiceNotes,
          user_reference_number: clientInvoiceNumber,
          load_length: loadLength === '' ? null : loadLength,
          amount: Math.round(invoiceAmount * 100),
          amount_funded: Math.round(amountFunded * 100),
          client_invoice_amount: Math.round(clientInvoiceAmount * 100),
          unpaid_purchase_amount: Math.round(unPaidPurchaseAmount * 100),
          net_worth: Math.round(debtorInvoiceAmount * 100),
          payment_method: paymentMethod,
          load_trailer_type: equipmentType === '' ? null : equipmentType,
          user_notes: notesFromClient,
          fuel_advance_amount: Math.round(fuelAdvanceAmount * 100),
          fuel_advance_rate: fuelAdvanceRate,
          fuel_advance_fee: Math.round(fuelAdvanceFee * 100),
          sales_rep_name: salesRep,
          factoring_amount: brokerGrossPayAmount,
          factoring_unpaid_purchase_amount: brokerPayAmount,
          contract_type: contractType,
          payment_profile_amount: Math.round(carrierPayAmount * 100),
        },
        {
          factoring: get(fundingRequest, ['factoring', 'id'], ''),
          debtor: debtor.id,
          bill_to_company_email: debtor.email,
          bill_to_company_phone: debtor.phone_number,
          bill_to_address: debtor.address,
          attachments
        }
      );

      const omitted = omit(merged, [
        'factoring_debtor_relation' // Object in Get, string in Patch
      ]);
      try {
        await updateFundingRequest(omitted);
        await API.factoring.fundingRequestCalculations({
        funding_request_id: fundingRequest.id,
        invoice_amount: Math.round(invoiceAmount * 100),
        debtor: debtor.id,
        factored: factored,
        factoring: get(fundingRequest, ['factoring', 'id'], ''),
        discount_rate_comfreight: discountRate,
        apply_reserve_holdback: applyReserveHoldback,
        contract_type: contractType,
        create_rates_revision: true,
        payment_profile: paymentProfileId,
        ...(() =>
          factored
            ? {
              payment_profile_invoice_amount: Math.round(carrierPayAmount),
              payment_speed: parseInt(
                (payoutDays.match(/\d+/g) || [])[0],
                0
              ),
              factoring_fee_split_rate: factoringFeeSplitRate
            }
            : {})()
      });
        // resetForm();
        openSnackbar('success', 'Funding Request Saved!');
      } catch (err) {
        let message = head(err);

        const strings = message.split(',');
        if (strings.length === 6) {
          message = strings.slice(3).join(', ');
        }
        openSnackbar('error', message);
      } finally {
        setSubmitting(false);
      }
    }
  }
};

const NOAUpdateForm = ({
  updateFactoringDebtor,
  fundingRequest,
  openSnackbar
}) => {
  return {
    enableReinitialize: true,
    initialValues: {
      noa: undefined
    },
    onSubmit: async (
      {
        noa,
        noa_active,
        noa_requested,
        noa_sent
      },
      { setSubmitting }
    ) => {
      try {
          await updateFactoringDebtor(
            get(fundingRequest, ['debtor', 'id'], ''),
            get(fundingRequest, ['factoring', 'id'], ''),
            {
              noa_sent,
              noa_requested,
              noa_active,
            });
        openSnackbar('success', 'NOA Status Updated!');
      } catch (err) {
        let message = head(err);

        const strings = message.split(',');
        if (strings.length === 6) {
          message = strings.slice(3).join(', ');
        }
        openSnackbar('error', message);
      } finally {
        setSubmitting(false);
      }
    }
  }
};
const getChargeBacksAndGiveBacksInfo = chargeBacksAndGiveBacks =>
  map(
    chargeBacksAndGiveBacks,
    ({
      id,
      category_name: lineItem,
      amount,
      balance_amount,
      invoice_number: invoiceNumber,
      age,
      line_item
    }) => {
      const relatedLineItem = get(line_item, 'id');
      const fundingRequest = get(line_item, 'funding_request');
      return {
        id,
        lineItem,
        amount,
        amountApplied: ({ id, isSelected, setFieldValue, values }) => {
          if (isSelected) {
            return (
              <PenniesInput
                name={id}
                value={values[id]}
                setFieldValue={setFieldValue}
                withStyle
              />
            );
          }
          return formatPennies(Math.abs(amount - balance_amount));
        },
        balance: balance_amount,
        invoiceNumber: () => (
          <a href={`/admin/fundingrequest/${fundingRequest}/`}>
            {invoiceNumber}
          </a>
        ),
        age: formatDays(age),
        relatedLineItem
      };
    }
  );

const chargeBackGivebackForm = ({
  chargeBacksAndGiveBacks: charges,
  userId,
  fetchChargebacksAndGivebacks,
  fetchLineItems,
  match: {
    params: { id }
  },
  linkRelatedLineItem,
  openSnackbar,
  fetchFundingRequest
}) => ({
  enableReinitialize: true,
  initialValues: charges.reduce(
    (acc, curr) => merge({}, acc, { [curr.id]: 0 }),
    {}
  ),
  onSubmit: async (values, { setSubmitting, resetForm }) => {
    try {
      const pairs = toPairs(values).filter(pair => pair[1]);
      await Promise.all(
        pairs.map(pair => {
          const index = findIndex(charges, charge => charge.id === pair[0]);
          return linkRelatedLineItem(
            charges[index].relatedLineItem,
            Math.round(pair[1] * 100)
          );
        })
      );
      openSnackbar('success', 'Chargeback Applied!');
    } catch (err) {
      openSnackbar('error', head(err));
    } finally {
      await Promise.all([
        fetchChargebacksAndGivebacks(userId),
        fetchLineItems(id),
        fetchFundingRequest(id)
      ]);
      setSubmitting(false);
      resetForm();
    }
  }
});

const placeNOAForm = ({
  noaAttachments,
  sendNOA,
  match: {
    params: { id }
  },
  debtorId,
  factoringId,
  openSnackbar
}) => {
  const attachment = get(head(noaAttachments), 'filename', '');
  return {
    enableReinitialize: true,
    initialValues: {
      noaToSend: attachment,
      attachments: noaAttachments
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await sendNOA(debtorId, factoringId, id, values.noaToSend);
        openSnackbar('success', 'NOA Sent!');
      } catch (err) {
        openSnackbar('error', head(err));
      } finally {
        setSubmitting(false);
      }
    }
  };
};

export const lineItemForm = ({
  closeEditLineItem,
  createLineItem,
  updateLineItem,
  openSnackbar,
  editLineItem,
  lineItems,
  fundingRequest
}) => {
  const index = findIndex(lineItems, item => item.id === editLineItem.id);

  const defaultValues = {
    amountRequested: 0,
    discountRate: get(fundingRequest, 'discount_rate', ''),
    againstWho: '',
    category: '',
    description: '',
    approve: 'approved',
    billWithFundingRequest: false
  };
  return {
    enableReinitialize: true,
    initialValues:
      index !== -1
        ? {
          amountRequested: lineItems[index].amount / 100,
          discountRate: lineItems[index].discountRate,
          againstWho: lineItems[index].appliedAgainst,
          category: lineItems[index].categoryId,
          description: lineItems[index].description,
          approve: lineItems[index].approve,
          billWithFundingRequest: lineItems[index].billWithFundingRequest,
          createdBy: lineItems[index]?.changedBy?.id,
        }
        : defaultValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (index === -1) {
          await createLineItem({
            amount_requested: Math.round(values.amountRequested * 100),
            category: values.category,
            description: values.description,
            discount_rate: values.discountRate,
            applied_against: values.againstWho,
            bill_with_funding_request: values.billWithFundingRequest,
            approval_status: values.approve
          });
          openSnackbar('success', 'Line Item Created!');
        } else {
          await updateLineItem({
            id: lineItems[index].id,
            amount_requested: Math.round(values.amountRequested * 100),
            category: values.category,
            description: values.description,
            discount_rate: values.discountRate,
            applied_against: values.againstWho,
            bill_with_funding_request: values.billWithFundingRequest,
            approval_status: values.approve
          });
          openSnackbar('success', 'Line Item Modified!');
        }
        closeEditLineItem();
      } catch (err) {
        openSnackbar('error', head(err));
      } finally {
        setSubmitting(false);
      }
    }
  };
};

const getAuditLog = (fundingRequest = {}) => fundingRequest.audit_log || [];

const mapStateToProps = (
  state,
  {
    match: {
      params: { id }
    }
  }
) => ({
  collatedPdf: getCollatedPdf,
  collatedDoc: getCollatedDoc(id)(state),
  invoiceDoc: getInvoiceDoc(id)(state),
  carrierInvoiceDoc: getCarrierInvoiceDoc(id)(state),
  editLineItem: getEditLineItem(state),
  dialog: getDialog(state),
  currentUserId: getCurrentUserId(state),
  userType: getUserType(state),
  factoringId: getFactoringIdForFundingRequest(id)(state),
  userId: getUserForFundingRequest(id)(state),
  debtorId: getDebtorForFundingRequest(id)(state),
  editNote: getEditNote(state),
  fundingRequest: getFundingRequest(id)(state),
  lineItems: getLineItems(id)(state),
  receivable: getReceivables(id)(state),
  purchases: getPurchases(id)(state),
  transactionNotes: getTransactionNotes(id)(state),
  clientNotes: getClientNotes(getFactoringIdForFundingRequest(id)(state))(
    state
  ),
  debtorRelation: getDebtorRelation(id)(state),
  chargeBacksAndGiveBacks: getChargeBacksAndGiveBacks(
    getUserForFundingRequest(id)(state)
  )(state),
  activeReserve: getActiveReserve(getUserForFundingRequest(id)(state))(state),
  pendingNegativeReserve: formatPennies(
    getPendingNegativeReserve(getUserForFundingRequest(id)(state))(state)
  ),
  pendingPositiveReserve: formatPennies(
    getPendingPositiveReserve(getUserForFundingRequest(id)(state))(state)
  ),
  lineItemCategories: getLineItemCategories(state),
  attachmentsByFactoringId: getAttachments(state),
  factoringProfileById: getFactoringProfile(state),
  debtorProfileById: getDebtorProfile(state),
  sendNOADialog: getSendNOA(state),
  isDeletingFundingRequest: getIsDeletingFundingRequest(state),
  statusAt: getFundingRequestStatusAt(id)(state)
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id }
    }
  }
) =>
  bindActionCreators(
    {
      fetchCommodities,
      fetchFreightClass,
      createTransactionNote,
      deleteTransactionNote,
      fetchClientNotes,
      fetchFundingRequest,
      fetchLineItems,
      fetchReserve,
      fetchReceivables,
      fetchPurchases,
      fetchTransactionNotes,
      openSnackbar,
      fetchDebtorRelation: fetchDebtorRelation(id),
      updateTransactionNote,
      updateNOADates: updateNOADates(id),
      updateFundingRequest,
      openEditNote,
      closeEditNote,
      fetchChargebacksAndGivebacks,
      linkRelatedLineItem: linkRelatedLineItem(id),
      fetchLineItemCategories,
      deleteLineItem,
      closeDialog,
      openDialog,
      openEditLineItem,
      closeEditLineItem,
      createLineItem: createLineItem(id),
      updateLineItem: updateLineItem(id),
      fetchAttachments,
      fetchFactoringProfile,
      fetchDebtorProfile,
      sendNOA,
      openSendNOA,
      closeSendNOA,
      fetchReserveAggregate,
      deleteFundingRequest,
      fetchCollated,
      fetchInvoice,
      fetchCarrierInvoice,
      deleteAttachment,
      createClientNote,
      updateClientNote,
      deleteClientNote,
      clearFiles,
      ReversalLineItems,
      updateFactoringDebtor
    },
    dispatch
  );

const mergeProps = (state, dispatch, props) => {
  const {
    fundingRequest = {},
    lineItems,
    receivable,
    chargeBacksAndGiveBacks,
    purchases,
    debtorRelation
  } = state;
  const editableInvoiceAmount = !isEmpty(filter(lineItems, ["category_name", "Change Debtor Invoice Amount"]));
  const editablePaymentSpeed = !isEmpty(filter(lineItems, ["category_name", "Change Payment Speed"]));
  return {
    ...state,
    ...dispatch,
    ...props,
    status: fundingRequest.status,
    nonFactored: fundingRequest.non_factored,
    statusColor: statusColor(fundingRequest.status),
    factoringClientId: get(fundingRequest, ['factoring', 'id'], ''),
    paymentProfileId: get(fundingRequest, 'payment_profile_id'),
    PaymentProfilePaymentMethodType: get(fundingRequest, 'payment_profile_payment_method'),
    paymentProfileDiscountRate: get(
      fundingRequest,
      'payment_profile_discount_rate'
    ),
    factoringClientName: get(
      fundingRequest,
      ['factoring', 'company_profile', 'name'],
      ''
    ),
    enableReserveHoldbackHybrid: get(
      fundingRequest,
      'factoring.enable_reserve_holdback_hybrid',
      false
    ),
    allowSelfFinanceFR: get(
      fundingRequest,
      'factoring.allow_self_finance_fr',
      false
    ),
    debtorInfo: getDebtorInfo(fundingRequest),
    generalInfo: getGeneralInfo(fundingRequest, receivable),
    lineItems: getLineItemsTable(
      lineItems,
      dispatch.openDialog,
      dispatch.openEditLineItem,
      fundingRequest.status
    ),
    chargeBacksAndGiveBacks: getChargeBacksAndGiveBacksInfo(
      chargeBacksAndGiveBacks
    ),
    financesInfo: getFinancesInfo(purchases),
    noaSummary: getNOASummery(debtorRelation),
    auditLog: getAuditLog(fundingRequest),
    noaAttachments: state
      .attachmentsByFactoringId(state.factoringId)
      .filter(
        attachment =>
          attachment.category === 'noa' ||
          attachment.category === 'comfreight_noa'
      )
      .map(attachment => ({
        ...attachment,
        modified: formatDate(attachment.modified)
      })),
    factoringProfile: state.factoringProfileById(state.factoringId),
    debtorProfile: state.debtorProfileById(state.debtorId),
    source: get(fundingRequest, 'source', ''),
    accounts: fundingRequest.accounts,
    requestedAt: fundingRequest.created,
    factoringMinimumFee: get(fundingRequest, 'factoring.minimum_fee', 0) / 100,
    editableInvoiceAmount,
    editablePaymentSpeed,
    debtorRelationId: get(debtorRelation, 'id', undefined),
    contract: get(fundingRequest, 'contract', undefined),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withState('freightClasses', 'setFreightClasses', []),
  withState('commodities', 'setCommodities', []),
  withState('attachments', 'setAttachments', []),
  withState('showCGGBButton', 'setShowCGGBButton', true),
  withState('isLoadingCBGB', 'setLoadingCBGB', false),
  withHandlers({
    sortByCategory: ({ attachments, setAttachments }) => () => {
      const sortedAttachments = sortBy(attachments, ['category']);
      setAttachments(sortedAttachments);
    }
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const {
        match: {
          params: { id }
        },
        factoringId,
        debtorId,
        userId,
        fundingRequest,
        setAttachments
      } = this.props;
      const {
        match: {
          params: { id: prevId }
        },
        factoringId: prevFactoringId,
        debtorId: prevDebtorId,
        userId: prevUserId,
        fundingRequest: prevFundingRequest
      } = prevProps;
      if (
        fundingRequest &&
        get(fundingRequest, 'attachments', []).length >
        get(prevFundingRequest, 'attachments', []).length
      ) {
        setAttachments(fundingRequest.attachments);
      }
      if (prevId !== id) {
        this.props.fetchReceivables(id);
        this.props.fetchFundingRequest(id);
        this.props.fetchLineItems(id);
        this.props.fetchPurchases(id);
        this.props.fetchTransactionNotes(id);
        this.props.fetchInvoice(id);
        this.props.fetchCarrierInvoice(id);
        this.props.fetchCollated(id);
      }

      if (debtorId !== prevDebtorId) {
        this.props.fetchDebtorProfile(debtorId);
      }

      if (debtorId !== prevDebtorId || factoringId !== prevFactoringId) {
        this.props.fetchDebtorRelation(debtorId, factoringId);
      }

      if (factoringId !== prevFactoringId) {
        this.props.fetchAttachments(factoringId);
        this.props.fetchFactoringProfile(factoringId);
        this.props.fetchClientNotes(factoringId);
      }

      if (userId !== prevUserId) {
        this.props.fetchReserve(userId);
        this.props.fetchReserveAggregate(userId);
      }
    },
    async componentDidMount() {
      const {
        match: {
          params: { id }
        },
        factoringId,
        debtorId,
        userId
      } = this.props;

      if (id) {
        this.props.fetchReceivables(id);
        this.props.fetchFundingRequest(id);
        this.props.fetchLineItems(id);
        this.props.fetchPurchases(id);
        this.props.fetchTransactionNotes(id);
        this.props.fetchInvoice(id);
        this.props.fetchCarrierInvoice(id);
        this.props.fetchCollated(id);
      }

      if (debtorId) {
        this.props.fetchDebtorProfile(debtorId);
      }

      if (userId) {
        this.props.fetchReserve(userId);
        this.props.fetchReserveAggregate(userId);
      }

      if (factoringId) {
        this.props.fetchClientNotes(factoringId);
        this.props.fetchAttachments(factoringId);
        this.props.fetchFactoringProfile(factoringId);
      }

      if (factoringId && debtorId) {
        this.props.fetchDebtorRelation(debtorId, factoringId);
      }

      this.props.fetchLineItemCategories({ funding_request_id: id });
      const commodities = await this.props.fetchCommodities();
      this.props.setCommodities(commodities);
      const freightClasses = await this.props.fetchFreightClass();
      this.props.setFreightClasses(freightClasses);
    }
  }),
  withProps(props => ({
    fundingRequestForm: fundingRequestForm(props),
    noaForm: noaForm(props),
    noaActiveForm: noaActiveForm(props),
    chargeBackGivebackForm: chargeBackGivebackForm(props),
    lineItemForm: lineItemForm(props),
    handleRoute: route => props.history.push(route),
    placeNOAForm: placeNOAForm(props),
    NOAUpdateForm: NOAUpdateForm(props),
    handleDeleteAttachment: id => {
      props.openDialog(
        'delete-funding-request-attachment',
        'Confirm Delete',
        'Are you sure you want to delete this Attachment?',
        {
          id
        }
      );
    },
    handleDeleteAttachmentConfirm: async () => {
      const {
        dialog,
        closeDialog,
        openSnackbar,
        deleteAttachment,
        fetchFundingRequest
      } = props;
      try {
        const noteId = get(dialog, 'data.id', '');
        await deleteAttachment(props.match.params.id, noteId);
        await fetchFundingRequest(props.match.params.id);
        openSnackbar('success', 'Deleted Attachment!');
      } catch (err) {
        openSnackbar('error', head(err));
      } finally {
        await closeDialog();
      }
    },
    statusDateTime: get(
      findLast(
        get(props.fundingRequest, 'status_timeline', []),
        value => value.status === get(props.fundingRequest, 'status', '')
      ),
      'status_set_datetime',
      get(props.fundingRequest, 'modified', '')
    ),
    handleCBGBLoadButtonClick: async () => {
        if(props.userId) {
            props.setShowCGGBButton(false);
            props.setLoadingCBGB(true);
            await props.fetchChargebacksAndGivebacks(props.userId)
            props.setLoadingCBGB(false);
        }
    }
  })),
  withTabs
)(PureFundingRequest);
