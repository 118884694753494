import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { Formik, FormikConfig } from 'formik';
import { Redirect } from 'react-router-dom';
import { get, isEqual, filter, isEmpty, forEach, concat } from 'lodash';
import InternalLayout from '../../../components/layouts/InternalLayout';
import { NOAActiveForm, PlaceNOAForm } from '../../../components/NOASummary';
import Can from '../../../components/Can';
import OverlayScreen from '../../../components/OverlayScreen';
import Title from './components/Title';
import AuditTrail, { AuditLogEntry } from '../../../components/AuditTrail';
import Purchases from './components/Purchases';
import Receivables from './components/Receivables';
import LineItems from './components/LineItems';
import ActiveReserveCard from './components/ActiveReserveCard';
import PendingNegativeReserveCard from './components/PendingNegativeReserveCard';
import PendingPositiveReserveCard from './components/PendingPositiveReserveCard';
import DebtorInfoCard from './components/DebtorInfoCard';
import InvoiceInfoCard, { Account } from './components/InvoiceInfoCard';
import FactoringClientInfo from './components/FactoringClientInfo';
import FactoringClientCarrierInfo from './components/FactoringClientCarrierInfo';
import StatusInfoCard from './components/StatusInfoCard';
import ChargebackGivebackCard, {
    ChargeBackGiveBackForm,
} from './components/ChargebackGivebackCard';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import LineItemDialog from './components/LineItemDialog';
import attachmentCategories from './attachmentCategories';
import InternalTransactionNotes from './components/InternalTransactionNotes';
import InternalClientNotes, { Note } from './components/InternalClientNotes';
import { Attachment } from '../../../components/AttachmentsCard';
import LineItemHistory from './components/LineItemHistory';
import ImageCropper from '../../../components/ImageCropper';
import FuelAdvancePurchase from './components/FuelAdvancePurchase';
import api from '../../../api';
import UploadAttachments from './components/UploadAttachments';
import ReversalDialog from './components/LineItems/components/ReversalDialog/';
import ObligationsTab from './components/ObligationsTab';
import head from 'lodash/head';
import { markPurchaseAsFailed } from '../../../api/factoring';
import { useUploadContext } from '../../../context/UploadContext';
import ProfileSection from '../../../components/ProfileSection';
import Card from '../../../components/Card';
import WaitView from '../../../components/WaitView';


interface Debtor {
    id: string;
    company_name: string;
    dba: string;
    mc: string;
    dot: string;
    credit_approved: string;
    originals_required: boolean;
    requires_scanned_copy: boolean;
}

interface FundingRequestFormFields {
    invoiceNotes: string;
    notesFromClient: string;
    missingBOL: boolean;
    missingRateCon: boolean;
    missingReceipt: boolean;
    blurryBOL: boolean;
    blurryRateCon: boolean;
    blurryReceipt: boolean;
    attachments: Attachment[];
    accounts: Account[];
    status: string;
    statusNote: string;
    invoiceNumber: string;
    invoiceAmount: number;
    loadNumber: string;
    clientInvoiceNumber: string;
    firstPickUpAddress: string;
    finalDeliveryAddress: string;
    equipmentType: string;
    loadLength: string;
    debtor: Debtor;
    amountFunded: number;
    clientInvoiceAmount: number;
    unPaidPurchaseAmount: number;
    debtorInvoiceAmount: number;
    paymentMethod: string;
    carrierPayAmount: number;
    brokerPayAmount: number;
    brokerGrossPayAmount: number;
    brokerDiscountAmount: number;
    carrierDiscountAmount: number;
    payoutDays: string;
    fuelAdvanceAmount: number;
    fuelAdvanceRate: string;
    fuelAdvanceFee: number;
    commodity: string;
    freightClass: string;
    assignedAdmin: string;
    invoiceDate: string;
    carrierPurchaseAmount: number;
    carrierFundedAmount: number;
    brokerFundedAmount: number;
    factoringFeeSplitRate: string;
    reserveHoldback: number;
    carrierProNumber: string;
    factored: boolean;
    paymentProfile: {
        id: string;
        factoring_company: {};
    };
    discountRate: number | string;
    paymentProfileDiscountRate: number | string;
    factoringCompany: {};
    factoringDiscountRate: string;
    paymentProfileAccounts: Account[];
    PaymentProfilePaymentMethod: Account;
    fuelAdvancePaymentMethod: Account;
    nonFactoredType: string;
    applyReserveHoldback: boolean;
    selfFinanceRequested: boolean;
    poNumber: string;
    carrierPaymentMethod: string;
    noa: string;
    contractType: string;
}

interface LineItemFormFields {
    amountRequested: number;
    discountRate: string | number;
    againstWho: string;
    category: string;
    description: string;
    approve: string;
    billWithFundingRequest: boolean;
}

interface Category {
    id: string;
    name: string;
    appliedAgainst: string;
}

export interface NOAUpdateFormValues {
    noa: string;
    noa_requested: string;
    noa_active: string;
    noa_sent: string;
}

interface Props {
    factoringMinimumFee: number;
    requested: boolean;
    editLineItem: {
        show: boolean;
    };
    dialog: {
        variant: string;
        message: string;
        show: boolean;
        title: string;
        data: any;
        action: string;
    };
    closeSnackbar: () => void;
    paymentProfileId: string;
    snackbar: {
        variant: 'success' | 'warning' | 'error' | 'info';
        show: boolean;
        message: string;
    };
    status: string;
    debtorInfo: {
        dba: string;
        company_name: string;
        phoneNumber: string;
        email: string;
        credit: string;
        credit_approved_note: string;
        phone_number: string;
        address: string;
        remit_notes: string;
    };
    generalInfo: {
        rate: number;
        factoringDiscountRate: string;
        requestedAt: string;
        fundedAt: string;
        salesRep: string;
        transactionRep: string;
        receivableStatus: string;
    };
    activeReserve: string;
    pendingNegativeReserve: string;
    pendingPositiveReserve: string;
    lineItems: Record<string, any>[];
    statusColor: string;
    chargeBacksAndGiveBacks: Record<string, any>[];
    financesInfo: Record<string, any>;
    noaSummary: {
        active: string;
        placed: string;
        sent: string;
        requested: string;
        relationshipCreated: string;
    };
    fundingRequestForm: FormikConfig<FundingRequestFormFields>;
    updateTransactionNote: Function;
    chargeBackGivebackForm: ChargeBackGiveBackForm;
    lineItemForm: FormikConfig<LineItemFormFields>;
    lineItemCategories: Category[];
    deleteLineItem: Function;
    closeDialog: Function;
    openEditLineItem: () => void;
    closeEditLineItem: MouseEventHandler<HTMLButtonElement>;
    auditLog: AuditLogEntry[];
    factoringClientId: string;
    factoringClientName: string;
    openDialog: Function;
    deleteFundingRequest: Function;
    isDeletingFundingRequest: boolean;
    match: {
        params: {
            id: string;
        };
    };
    history: any;
    source: string;
    placeNOAForm: PlaceNOAForm;
    NOAUpdateForm: FormikConfig<NOAUpdateFormValues>;
    attachments: Record<string, any>[];
    collatedDoc: Record<string, any>;
    invoiceDoc: Record<string, any>;
    carrierInvoiceDoc: Record<string, any>;
    transactionNotes: Record<string, any>[];
    clientNotes: Note[];
    createTransactionNote: Function;
    openSnackbar: Function;
    deleteTransactionNote: Function;
    handleDeleteAttachmentConfirm: MouseEventHandler<HTMLButtonElement>;
    handleDeleteAttachment: (id: string) => void;
    createClientNote: Function;
    updateClientNote: Function;
    deleteClientNote: Function;
    noaActiveForm: NOAActiveForm;
    index: number;
    handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
    requestedAt: string;
    receivable: any[];
    factoringProfile: Record<string, any>;
    statusAt: string;
    nonFactored: boolean;
    freightClasses: any[];
    commodities: any[];
    sortByCategory?: Function;
    collatedPdf?: any;
    userType: string;
    currentUserId: string;
    enableReserveHoldbackHybrid: boolean;
    allowSelfFinanceFR: boolean;
    setAttachments: Function;
    clearFiles: Function;
    ReversalLineItems: Function;
    editableInvoiceAmount: boolean;
    editablePaymentSpeed: boolean;
    noaAttachments: [];
    debtorRelationId: string;
    contract: string;
    PaymentProfilePaymentMethodType: string;
    handleCBGBLoadButtonClick: MouseEventHandler<HTMLButtonElement>;
    showCGGBButton: boolean;
    isLoadingCBGB: boolean;
}

export default function FundingRequest({
    factoringMinimumFee,
    freightClasses,
    commodities,
    editLineItem,
    paymentProfileId,
    dialog,
    status,
    factoringClientId,
    debtorInfo,
    generalInfo,
    activeReserve,
    pendingNegativeReserve,
    pendingPositiveReserve,
    lineItems,
    statusColor,
    chargeBacksAndGiveBacks,
    financesInfo,
    noaSummary,
    fundingRequestForm,
    updateTransactionNote,
    chargeBackGivebackForm,
    lineItemForm,
    lineItemCategories,
    deleteLineItem,
    closeDialog,
    openEditLineItem,
    closeEditLineItem,
    auditLog,
    factoringProfile,
    openDialog,
    deleteFundingRequest,
    isDeletingFundingRequest,
    match: {
        params: { id },
    },
    history,
    source,
    placeNOAForm,
    attachments,
    collatedDoc,
    invoiceDoc,
    transactionNotes,
    clientNotes,
    createTransactionNote,
    openSnackbar,
    deleteTransactionNote,
    handleDeleteAttachmentConfirm,
    handleDeleteAttachment,
    createClientNote,
    updateClientNote,
    deleteClientNote,
    noaActiveForm,
    index,
    handleChange: handleTabChange,
    requestedAt,
    receivable,
    factoringClientName,
    statusAt,
    nonFactored,
    sortByCategory,
    collatedPdf,
    carrierInvoiceDoc,
    userType,
    currentUserId,
    enableReserveHoldbackHybrid,
    allowSelfFinanceFR,
    setAttachments,
    clearFiles,
    ReversalLineItems,
    editableInvoiceAmount,
    editablePaymentSpeed,
    noaAttachments,
    debtorRelationId,
    NOAUpdateForm,
    contract,
    PaymentProfilePaymentMethodType,
    handleCBGBLoadButtonClick,
    showCGGBButton,
    isLoadingCBGB
}: Props): JSX.Element {
    const [sortedLineItems, setSortedLineItems] = useState([]);
    const fetchAttachments = async () => {
        const res = await api.factoring.getFundingRequest(id);
        if (
            (!get(res, 'attachments', []).length &&
                get(res, 'attachments', []).length !==
                    (attachments || []).length) ||
            !isEqual(get(res, 'attachments', []), attachments)
        ) {
            setAttachments(get(res, 'attachments', []));
        }
    };
    useUploadContext(fetchAttachments);

    useEffect(() => {
        fetchAttachments();
    }, []);
useEffect((): any => {
    const newLineItemsArray = new Array;
    const adjustmentLineItems = filter(lineItems, lineItem => !isEmpty(lineItem.adjustedLineItem));
    const regularLineItems = filter(lineItems, lineItem => isEmpty(lineItem.adjustedLineItem));
    forEach(regularLineItems, function (value, key) {
      const adjustmentLineItem = filter(adjustmentLineItems, lineItem => lineItem.adjustedLineItem === value.id);
      newLineItemsArray.push(value);
      if (adjustmentLineItem.length) {
        newLineItemsArray.push(...adjustmentLineItem);
      }
    });
    setSortedLineItems(newLineItemsArray as []);
  }, [lineItems.length])
  const handleConfirmResubmitFR = async (data: any) => {
    try {
      const res = await api.factoring.resubmitFundingRequest(
        get(data, 'fundingRequest'),
        get(data, 'fundingRequestPurchase')
      );
      openSnackbar(
        'success',
        'Funding Request has been successfully resubmitted.'
      );
    } catch (e) {
      openSnackbar(
        'error',
        head(e)
      );
    }
    closeDialog();
  };
  const handleConfirmMarkAsFailedFRPurchase = async (data: any) => {
    try {
      const res = await api.factoring.markPurchaseAsFailed(
          get(data, 'fundingRequestId'),
          get(data, 'fundingRequestPurchaseId')
      );
      openSnackbar(
        'success',
        'Funding Request Purchase has been successfully marked as failed.\n' +
          'Please reload page.'
      );
    } catch (e) {
      openSnackbar(
        'error',
        head(e)
      );
    }
    closeDialog();
  };
  return (
    <Can
      perform="admin-funding-request:view"
      yes={(): JSX.Element => (
        <InternalLayout color={paymentProfileId ? '#FFE1C6' : undefined}>
          <React.Fragment>
            {isDeletingFundingRequest && (
              <OverlayScreen>
                <CircularProgress />
              </OverlayScreen>
            )}
            <Formik {...fundingRequestForm}>
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }): JSX.Element => (
                <React.Fragment>
                  <React.Fragment>
                    <Grid container direction="column" spacing={8}>
                      <Grid item style={{ width: '100%' }}>
                        <Title
                          status={status}
                          statusColor={statusColor}
                          handleSubmit={(): void => handleSubmit()}
                          isSubmitting={isSubmitting}
                          openDialog={openDialog}
                          fundingRequestId={id}
                          paymentProfileId={paymentProfileId}
                          nonFactored={nonFactored}
                          contractType={values.contractType}
                        />
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Grid container spacing={16}>
                          <Grid item sm={3}>
                            <Grid container direction="column" spacing={16}>
                              <Grid item style={{ width: '100%' }}>
                                <ActiveReserveCard amount={activeReserve} />
                              </Grid>
                              <Grid item style={{ width: '100%' }}>
                                <InvoiceInfoCard
                                  {...values}
                                  commodities={commodities}
                                  freightClasses={freightClasses}
                                  errors={errors}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  paymentProfileId={paymentProfileId}
                                  editableInvoiceAmount={editableInvoiceAmount}
                                  PaymentProfilePaymentMethodType={PaymentProfilePaymentMethodType}
                                  fundingRequestId={id}
                                  factoringClientId={factoringClientId}
                                  values={values}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sm={6}>
                            <Grid container direction="column" spacing={16}>
                              <Grid
                                container
                                item
                                spacing={16}
                                style={{ width: '100%' }}
                              >
                                <Grid item sm={6}>
                                  <PendingNegativeReserveCard
                                    amount={pendingNegativeReserve}
                                  />
                                </Grid>
                                <Grid item sm={6}>
                                  <PendingPositiveReserveCard
                                    amount={pendingPositiveReserve}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ width: '100%' }}>
                                <Grid container spacing={16}>
                                  <Grid item sm={6}>
                                    <DebtorInfoCard
                                      {...debtorInfo}
                                      setFieldTouched={setFieldTouched}
                                      setFieldValue={setFieldValue}
                                      handleBlur={handleBlur}
                                      debtor={values.debtor}
                                      {...noaSummary}
                                      noaForm={placeNOAForm}
                                      debtorName={
                                        debtorInfo.dba ||
                                        debtorInfo.company_name
                                      }
                                      clientName={factoringClientName}
                                      noaActiveForm={noaActiveForm}
                                      noaAttachments={noaAttachments}
                                      noa={values.noa}
                                      debtorRelationId={debtorRelationId}
                                      NOAUpdateForm={NOAUpdateForm}
                                    />
                                  </Grid>
                                  <Grid item sm={6}>
                                    {paymentProfileId ? (
                                      <FactoringClientCarrierInfo
                                        factoring={factoringClientId}
                                        paymentProfile={paymentProfileId}
                                        factoringCompany={
                                          values.factoringCompany
                                        }
                                        status={status}
                                        setFieldValue={setFieldValue}
                                        factoringClientId={factoringClientId}
                                      />
                                    ) : (
                                      <FactoringClientInfo
                                        id={factoringClientId}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Can
                                perform="admin-funding-request-chargebackgiveback:view"
                                yes={() => (showCGGBButton
                                  ?
                                    <Grid item style={{ width: '100%', padding: 12 }}>
                                      <Grid container direction="column" spacing={8}>
                                        <Card style={{ padding: 8}}>
                                          <Grid item>
                                            <ProfileSection>
                                              Chargebacks / Givebacks
                                            </ProfileSection>
                                          </Grid>
                                          <Grid item>
                                            <Button
                                              type="button"
                                              variant="raised"
                                              onClick={handleCBGBLoadButtonClick}
                                            >
                                              Retrieve CBGB data
                                            </Button>
                                          </Grid>
                                        </Card>
                                      </Grid>
                                    </Grid>
                                  :
                                    isLoadingCBGB ?
                                      <Grid item style={{ width: '100%', padding: 12 }}>
                                        <Grid container direction="column" spacing={8}>
                                          <Card style={{ padding: 8}}>
                                            <Grid item>
                                              <WaitView height="unset" />
                                            </Grid>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    :
                                      <Grid item>
                                        <ChargebackGivebackCard
                                          chargeBacksAndGiveBacks={
                                            chargeBacksAndGiveBacks
                                          }
                                          chargeBackGivebackForm={
                                            chargeBackGivebackForm
                                          }
                                          lineItemCategories={lineItemCategories}
                                          status={status}
                                        />
                                      </Grid>
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid item sm={3}>
                            <StatusInfoCard
                              factoringID={id}
                              factoringClientId={factoringClientId}
                              paymentProfileId={paymentProfileId}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              {...generalInfo}
                              {...values}
                              source={source}
                              attachments={attachments}
                              values={values}
                              handleDeleteAttachment={handleDeleteAttachment}
                              attachmentCategories={attachmentCategories}
                              setFieldTouched={setFieldTouched}
                              collatedDoc={collatedDoc}
                              collatedPdf={collatedPdf}
                              invoiceDoc={invoiceDoc}
                              requestedAt={requestedAt}
                              currentStatusAt={statusAt}
                              sortByCategory={sortByCategory}
                              carrierInvoiceDoc={carrierInvoiceDoc}
                              fundingRequestID={id}
                              userType={userType}
                              currentUserId={currentUserId}
                              enableReserveHoldbackHybrid={
                                enableReserveHoldbackHybrid
                              }
                              allowSelfFinanceFR={allowSelfFinanceFR}
                              factoringMinimumFee={factoringMinimumFee}
                              factoringCompany={values.factoringCompany}
                              editablePaymentSpeed={editablePaymentSpeed}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: '100%' }}>
                        <Paper>
                          <Can
                            perform="admin-funding-request-tabs:view"
                            yes={() => (
                              <React.Fragment>
                                <Tabs
                                  value={index}
                                  onChange={handleTabChange}
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="fullWidth"
                                >
                                  <Tab label="Internal Transaction Notes" />
                                  <Tab label="Internal Client Notes" />
                                  <Tab label="Audit Trail" />
                                  <Tab label="Purchases" />
                                  <Tab label="Receivables" />
                                  <Tab label="Line Items" />
                                  {
                                      (values.selfFinanceRequested || contract) &&
                                      <Tab label="Obligations" />
                                  }
                                </Tabs>
                                <div
                                  style={{
                                    padding: 32,
                                  }}
                                >
                                  {index === 0 && (
                                    <InternalTransactionNotes
                                      createTransactionNote={(
                                        note,
                                        noteAttachments
                                      ): void =>
                                        createTransactionNote(
                                          id,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      updateTransactionNote={(
                                        noteId,
                                        note,
                                        noteAttachments
                                      ): void =>
                                        updateTransactionNote(
                                          id,
                                          noteId,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      openSnackbar={openSnackbar}
                                      transactionNotes={transactionNotes}
                                      deleteTransactionNote={(
                                        noteId: string
                                      ): void =>
                                        deleteTransactionNote(id, noteId)
                                      }
                                    />
                                  )}
                                  {index === 1 && (
                                    <InternalClientNotes
                                      createClientNote={(
                                        note,
                                        noteAttachments
                                      ): void =>
                                        createClientNote(
                                          factoringProfile.id,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      updateClientNote={(
                                        noteId,
                                        note,
                                        noteAttachments
                                      ): void =>
                                        updateClientNote(
                                          factoringProfile.id,
                                          noteId,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      openSnackbar={openSnackbar}
                                      clientNotes={clientNotes}
                                      deleteClientNote={async (
                                        noteId: string
                                      ): Promise<void> =>
                                        deleteClientNote(
                                          factoringProfile.id,
                                          noteId
                                        )
                                      }
                                    />
                                  )}
                                  {index === 2 && (
                                    <AuditTrail
                                      auditLog={auditLog}
                                      prefix="funding_request_"
                                    />
                                  )}
                                  {index === 3 && (
                                    <Purchases
                                      purchases={financesInfo.purchases}
                                    />
                                  )}
                                  {index === 4 && (
                                    <Receivables receivables={receivable} />
                                  )}
                                  {index === 5 && (
                                    <LineItems
                                      lineItems={sortedLineItems}
                                      openEditLineItem={openEditLineItem}
                                    />
                                  )}
                                  {index === 6 && (
                                    <ObligationsTab
                                      contractId={contract}
                                      useNewBrokerFR={get(factoringProfile, "company_settings.use_new_broker_funding_requests", false)}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            )}
                            no={() => (
                              <React.Fragment>
                                <Tabs
                                  value={index}
                                  onChange={handleTabChange}
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="fullWidth"
                                >
                                  <Tab label="Internal Transaction Notes" />
                                  <Tab label="Audit Trail" />
                                  <Tab label="Line Items" />
                                </Tabs>
                                <div
                                  style={{
                                    padding: 32,
                                  }}
                                >
                                  {index === 0 && (
                                    <InternalTransactionNotes
                                      createTransactionNote={(
                                        note,
                                        noteAttachments
                                      ): void =>
                                        createTransactionNote(
                                          id,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      updateTransactionNote={(
                                        noteId,
                                        note,
                                        noteAttachments
                                      ): void =>
                                        updateTransactionNote(
                                          id,
                                          noteId,
                                          note,
                                          noteAttachments
                                        )
                                      }
                                      openSnackbar={openSnackbar}
                                      transactionNotes={transactionNotes}
                                      deleteTransactionNote={(
                                        noteId: string
                                      ): void =>
                                        deleteTransactionNote(id, noteId)
                                      }
                                    />
                                  )}
                                  {index === 1 && (
                                    <AuditTrail
                                      auditLog={auditLog}
                                      prefix="funding_request_"
                                    />
                                  )}
                                  {index === 2 && (
                                    <LineItems
                                      lineItems={sortedLineItems}
                                      openEditLineItem={openEditLineItem}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            )}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                  {dialog.variant === 'confirm' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      handleConfirm={async (): Promise<void> => {
                        deleteLineItem(
                          get(dialog, 'data.fundingRequest'),
                          get(dialog, 'data.lineItem')
                        ).finally((): void => {
                          closeDialog();
                        });
                      }}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  <LineItemDialog
                    open={editLineItem.show}
                    type={get(editLineItem, 'data.type')}
                    status={status}
                    lineItemForm={lineItemForm}
                    categories={lineItemCategories}
                    handleClose={closeEditLineItem}
                  />
                  {dialog.variant === 'confirm_delete_funding_request' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      handleConfirm={async (): Promise<void> => {
                        try {
                          await deleteFundingRequest({
                            id: get(dialog, 'data.fundingRequestId'),
                          });
                          closeDialog();
                          openSnackbar(
                            'success',
                            'Funding Request has been deleted'
                          );
                          await history.go(-1);
                        } catch (err) {
                          closeDialog();
                          openSnackbar(
                            'error',
                            'Error deleting Funding Request'
                          );
                        }
                      }}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'delete-funding-request-attachment' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      title={dialog.title}
                      handleConfirm={handleDeleteAttachmentConfirm}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'resubmit-funding-request' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      title={dialog.title}
                      handleConfirm={() => handleConfirmResubmitFR(dialog.data)}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'mark-as-failed-funding-request-purchase' && (
                    <ConfirmDialog
                      open={dialog.show}
                      message={dialog.message}
                      title={dialog.title}
                      handleConfirm={() => handleConfirmMarkAsFailedFRPurchase(dialog.data)}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'view-lineitem-history' && (
                    <LineItemHistory
                      open={dialog.show}
                      handleExit={(): void => closeDialog()}
                    />
                  )}
                  {dialog.variant === 'make_fuel_advance_payment' && (
                    <FuelAdvancePurchase
                      fuelAdvanceAmount={values.fuelAdvanceAmount}
                      amount={values.invoiceAmount}
                      fundingRequest={id}
                      handleExit={(): void => closeDialog()}
                      open={dialog.show}
                    />
                  )}
                  {dialog.variant === 'upload-funding-request-attachments' && (
                    <UploadAttachments
                      fundingRequestId={id}
                      handleClose={(): void => {
                        clearFiles();
                        closeDialog();
                      }}
                      open={dialog.show}
                    />
                  )}
                  {dialog.variant === 'line-item-reversal' && (
                    <ReversalDialog
                      amount={get(dialog, 'data.amount')}
                      fundingRequestId={id}
                      itemId={get(dialog, 'data.itemId')}
                      adjustmentItem={get(dialog, 'data.adjustmentItem') }
                      handleClose={(): void => closeDialog()}
                      open={dialog.show}
                      ReversalLineItems={ReversalLineItems}
                      openSnackbar={openSnackbar}
                    />
                  )}
                </React.Fragment>
              )}
            </Formik>
          </React.Fragment>
        </InternalLayout>
      )}
      no={(): JSX.Element => <Redirect to="/" />}
    />
  );
}
