import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
} from 'react';

export const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const [uploadedEvent, setUploadedEvent] = useState(false);

    const triggerUploadEvent = () => {
        setUploadedEvent(true);
    };

    return (
        <UploadContext.Provider
            value={{ uploadedEvent, triggerUploadEvent, setUploadedEvent }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export const useUploadContext = (refetch) => {
    const context = useContext(UploadContext);

    if (!context) {
        throw new Error(
            'useUploadContext must be used within an UploadProvider',
        );
    }

    const { uploadedEvent, setUploadedEvent, triggerUploadEvent } = context;

    const previousUploadedEvent = useRef(uploadedEvent);

    useEffect(() => {
        if (
            uploadedEvent &&
            uploadedEvent !== previousUploadedEvent.current &&
            refetch
        ) {
            const intervalId = setInterval(() => {
                previousUploadedEvent.current = true;
                refetch();
            }, 10000);

            const timeoutId = setTimeout(() => {
                clearInterval(intervalId);
                setUploadedEvent(false);
                previousUploadedEvent.current = false;
            }, 60000);

            // Cleanup logic
            return () => {
                if (!uploadedEvent) {
                    clearInterval(intervalId);
                    clearTimeout(timeoutId);
                }
            };
        }
    }, [uploadedEvent, refetch]);

    return { uploadedEvent, triggerUploadEvent };
};
