const handleLoginAsUser = () => async (dispatch, getState, { api }) => {
  const state = getState();

  const token = await api.admin.getLoginToken(
    state?.user?.selectedUser?.data?.id
  );
  window.open(`${process.env.REACT_APP_COMFREIGHT_URL}login?as=${token.token}&loggingInAsUser=1`);
};

export default handleLoginAsUser;
