import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, shallowEqual } from 'react-redux';
import { isArray } from 'lodash';
import { Button } from '@material-ui/core';
import {
    clearFiles,
    getFiles,
    getNewFiles,
    pickFiles,
    updateCategories,
    uploadFiles,
    updateVisibleTo,
} from '../../modules/upload';

import { UploadContext } from '../../context/UploadContext';
import categories from '../../helpers/categories';
import UploadListFilesView from './UploadListFilesView';
import DropZoneUpload from './DropZoneUpload';
import FileTypeConfirmModal from '../FileTypeConfirmModal';

export class PureUploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayFileTypeConfirmation: false,
        };
        this.uploadBtn = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const {
            newFiles,
            uploadFiles,
            type = 'factoring',
            baseId,
            triggerUploadEvent,
        } = this.props;
        if (!shallowEqual(prevProps.newFiles, newFiles)) {
            uploadFiles(newFiles, type, baseId);
            triggerUploadEvent();
        }
    }

    categoriesHandler = (event, i) => {
        const { updateCategories: updateCategoriesProps, type = 'factoring' } =
            this.props;
        if (isArray(event.target.value)) {
            updateCategoriesProps({
                position: i,
                categories: event.target.value.join(','),
                type,
            });
        } else {
            updateCategoriesProps({
                position: i,
                categories: event.target.value,
                type,
            });
        }
    };

    visibleToHandler = (event, i) => {
        const { updateVisibleTo: updateVisibleToProps } = this.props;
        updateVisibleToProps({
            position: i,
            visible_to: event,
        });
    };

    render() {
        const {
            files,
            pickFiles,
            requestId,
            categoriesProps = null,
            isClearFiles,
            clearFiles,
            multiple,
            noCategories,
            single,
            buttonClearText,
            clearFilesButtonAction,
            type,
            defaultCategoryValue,
            visibleTo,
        } = this.props;

        return (
            <React.Fragment>
                {files.length ? (
                    <React.Fragment>
                        <UploadListFilesView
                            UploadFiles
                            requestId={requestId}
                            pickFiles={pickFiles}
                            files={files}
                            categories={categoriesProps || categories}
                            categoriesHandler={this.categoriesHandler}
                            uploadBtnRef={this.uploadBtn}
                            multiple={multiple}
                            noCategories={noCategories}
                            single={single}
                            defaultCategoryValue={defaultCategoryValue}
                            openDialog={() =>
                                this.setState({
                                    displayFileTypeConfirmation: true,
                                })
                            }
                            visibleToHandler={this.visibleToHandler}
                            visibleTo={visibleTo}
                        />
                        {isClearFiles && (
                            <Button
                                variant="outlined"
                                component="span"
                                onClick={() => {
                                    if (clearFilesButtonAction)
                                        clearFilesButtonAction();
                                    clearFiles();
                                }}
                            >
                                {buttonClearText}
                            </Button>
                        )}
                    </React.Fragment>
                ) : (
                    <DropZoneUpload
                        requestId={requestId}
                        pickFiles={pickFiles}
                        files={files}
                        type={type}
                    />
                )}
                <FileTypeConfirmModal
                    open={this.state.displayFileTypeConfirmation}
                    customHandleClose={() =>
                        this.setState({
                            displayFileTypeConfirmation: false,
                        })
                    }
                />
            </React.Fragment>
        );
    }
}

PureUploadFile.defaultProps = {
    single: false,
    categories,
    files: [],
    isClearFiles: false,
    multiple: false,
    buttonClearText: 'Clear Documents',
    visibleTo: false,
};

PureUploadFile.propTypes = {
    files: PropTypes.array.isRequired,
    requestId: PropTypes.string.isRequired,
    categories: PropTypes.array,
    categoriesProps: PropTypes.array,
    single: PropTypes.bool,
    isClearFiles: PropTypes.bool,
    multiple: PropTypes.bool,
    noCategories: PropTypes.bool,
    buttonClearText: PropTypes.string,
    clearFilesButtonAction: PropTypes.func,
    visibleTo: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
    files: getFiles(state, props.type),
    newFiles: getNewFiles(state, props.type),
});

const mapDispatchToProps = {
    pickFiles,
    uploadFiles,
    updateCategories,
    clearFiles,
    updateVisibleTo,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
)((props) => (
    <UploadContext.Consumer>
        {({ triggerUploadEvent }) => (
            <PureUploadFile
                {...props}
                triggerUploadEvent={triggerUploadEvent}
            />
        )}
    </UploadContext.Consumer>
));
